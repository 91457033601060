import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

// Import fake backend
import fakeBackend from "./helpers/AuthType/fakeBackend";

// Activating fake backend
fakeBackend();




const App = (props) => {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [optionGroup, setOptionGroup] = useState([
    { label: "-- All Traffic --", options: [] },
  ]);
  const [trafficData, setTrafficData] = useState(null);

  // const [dateRange, setDateRange] = useState({
  //   startDate: null,
  //   endDate: null,
  // });
  const [dateRange, setDateRange] = useState({
    startDate: moment.utc().startOf('day').subtract(5, 'hours').toDate(),
    endDate: moment.utc().endOf('day').subtract(5, 'hours').toDate()
  });
  
  useEffect(() => {
    setDateRange({
      startDate: moment.utc().startOf('day').subtract(5,'hours').toDate(),
      endDate: moment.utc().endOf('day').subtract(5, 'hours').toDate()
    });
  }, []);
  
  
  const handleDateChange = ({ startDate, endDate }) => {
    setDateRange({ startDate, endDate });
    // console.log('Received Start Date:!!!!!!!!!!!!!!', startDate);
    // console.log('Received End Date:!!!!!!!!!!!!!!', endDate);
  };

  useEffect(() => {
    // console.log("Updated Date Range in Parent:", dateRange);
  }, [dateRange]);

  // Function to refresh the toll plaza data
  const refreshTollPlazaData = () => {
    
    fetch("https://trafficapp.applivity.com/api/tollplazas/folderNames", { method: "GET" })
      .then((response) => response.json())
      .then((data) => {
        const tollPlazaOptions = data.map((plazaName) => ({
          label: plazaName,
          value: plazaName,
        }));
        setOptionGroup([{ label: "", options: tollPlazaOptions }]);
      })
      .catch((error) => console.error("Error fetching TollPlaza:", error));
  };

  useEffect(() => {
    refreshTollPlazaData();
  }, []);

 
  useEffect(() => {
    if (selectedGroup) {
      const plazaName = encodeURIComponent(selectedGroup.value);
  
      // Adjust the time based on your logic
      const adjustTime = (date) => {
        if (!date) return null;
        return moment.utc(date).add(5, "hours");
      };
  
      const adjustedStart = adjustTime(dateRange.startDate);
      const adjustedEnd = adjustTime(dateRange.endDate);
  
      // Check if both adjustedStart and adjustedEnd are valid before formatting
      if (adjustedStart && adjustedEnd) {
        const start = adjustedStart.format("YYYY-MM-DD");
        const end = adjustedEnd.format("YYYY-MM-DD");
  
        // Create the EventSource URL with the adjusted date range
        const eventSource = new EventSource(
          `https://trafficapp.applivity.com/api/tollplazas/combined-data?plaza=${plazaName}&startDate=${start}&endDate=${end}`
        );
  
        // Handle incoming data
        eventSource.onmessage = function (event) {
          if (event.data === '{"done": true}') {
            // console.log("All data received");
            eventSource.close(); // Close the connection once the stream is done
          } else {
            const data = JSON.parse(event.data); // Parse the valid JSON data
            setTrafficData(data); // Update the traffic data with the new data from the server
          }
        };
  
        // Handle errors
        eventSource.onerror = function (error) {
          // console.error("EventSource failed:", error);
          eventSource.close(); // Close the connection if there is an error
        };
  
        // Clean up the EventSource connection when the component unmounts or selectedGroup or dateRange changes
        return () => {
          eventSource.close();
        };
      }
    }
  }, [dateRange, selectedGroup]); // Depend on dateRange and selectedGroup to re-establish the SSE connection
  




 



// Function to handle the selection of a toll plaza folder
const handleSelectGroup = (selectedOption) => {
  // console.log('Selected Folder: ', selectedOption); // Log the selected folder
  setSelectedGroup(selectedOption); // Set the selected toll plaza folder
};

// useEffect to fetch data when either selectedGroup or dateRange changes
useEffect(() => {
  // console.log('Selected Group Changed: ', selectedGroup); // Log the selected group when it changes
  // console.log('Date Range Changed: ', dateRange); // Log the date range when it changes
  
  if (selectedGroup && dateRange.startDate && dateRange.endDate) {
    const plazaName = encodeURIComponent(selectedGroup.value);
    // console.log('Fetching data for Plaza: ', plazaName); // Log the plaza name being used

    // Adjust the time based on your logic
    const adjustTime = (date) => {
      if (!date) return null;
      return moment.utc(date).add(5, "hours");
    };

  

// Adjusted dates with 5 hours added
const adjustedStart = adjustTime(dateRange.startDate);
const adjustedEnd = adjustTime(dateRange.endDate);

// Keep the adjusted end date within the same day (end of the day)
const adjustedEndDay = adjustedStart.clone().endOf('day'); // Ensure the end date is at the end of the same day

// Convert the dates to UTC format
const start = adjustedStart.utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
const end = adjustedEndDay.utc().format("YYYY-MM-DDTHH:mm:ss[Z]");

// Log the dates in UTC format
// console.log('Start Date (UTC): ', start);
// console.log('End Date (UTC): ', end);

    if (adjustedStart && adjustedEnd) {

    // Fetch the combined data API
      fetch(
        `https://trafficapp.applivity.com/api/tollplazas/combined-data?plaza=${plazaName}&startDate=${start}&endDate=${end}`,
        { method: "GET" }
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log('Received Traffic Data: ', data); // Log the data received
          setTrafficData(data); // Update the traffic data with the new data from the server
        })
        .catch((error) => console.error("Error fetching dataset:", error));
    } else {
      console.error("Adjusted start or end date is invalid");
    }
  }
}, [selectedGroup, dateRange]); // This will trigger whenever either selectedGroup or dateRange changes


  const combineCounts = (isbCounts, lhrCounts) => {
    const combinedCounts = {};

    for (const vehicleType in isbCounts) {
      if (isbCounts.hasOwnProperty(vehicleType)) {
        combinedCounts[vehicleType] = {
          isbToLhr: isbCounts[vehicleType] || 0,
          lhrToIsb: lhrCounts[vehicleType] || 0,
          vehicleCount:
            (isbCounts[vehicleType] || 0) + (lhrCounts[vehicleType] || 0),
        };
      }
    }

    // Add remaining counts from LHR-ISB if any vehicle type is missing from ISB-TO-LHR
    for (const vehicleType in lhrCounts) {
      if (!combinedCounts[vehicleType]) {
        combinedCounts[vehicleType] = {
          isbToLhr: 0,
          lhrToIsb: lhrCounts[vehicleType] || 0,
          vehicleCount: 0 + (lhrCounts[vehicleType] || 0),
        };
      }
    }

    return combinedCounts;
  };

  // Extract total counts
  const totalIsbToLhrCounts = trafficData?.totalIsbToLhrCounts || {};
  const totalLhrToIsbCounts = trafficData?.totalLhrToIsbCounts || {};

  // Combine counts
  const combinedCounts = combineCounts(
    totalIsbToLhrCounts,
    totalLhrToIsbCounts
  );

  // Calculate the sum of each column
  const calculateColumnSum = (columnKey) => {
    return Object.values(combinedCounts).reduce(
      (sum, counts) => sum + counts[columnKey],
      0
    );
  };

  // Calculate total sums for each column
  const totalVehicleCount = calculateColumnSum("vehicleCount");
  const totalIsbToLhrCount = calculateColumnSum("isbToLhr");
  const totalLhrToIsbCount = calculateColumnSum("lhrToIsb");

  const getLayout = () => {
    switch (props.layout.layoutType) {
      case "horizontal":
        return HorizontalLayout;
      default:
        return VerticalLayout;
    }
  };

  const Layout = getLayout();

  return (
    <React.Fragment>
      <Routes>
        <Route>
          {authRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}
        </Route>

        <Route>
          {userRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <Layout
                  selectedGroup={selectedGroup}
                  optionGroup={optionGroup}
                  handleSelectGroup={handleSelectGroup}
                  trafficData={trafficData}
                  refreshTollPlazaData={refreshTollPlazaData}
                  totalVehicleCount={totalVehicleCount}
                  totalIsbToLhrCount={totalIsbToLhrCount}
                  totalLhrToIsbCount={totalLhrToIsbCount}
                  combinedCounts={combinedCounts}
                  onDateChange={handleDateChange}
                >
                  <Authmiddleware>
                    {React.cloneElement(route.component, {
                      selectedGroup,
                      optionGroup,
                      handleSelectGroup,
                      trafficData,
                      refreshTollPlazaData,
                      totalVehicleCount,
                      totalIsbToLhrCount,
                      totalLhrToIsbCount,
                      combinedCounts,
                      onDateChange: handleDateChange,
                    })}
                  </Authmiddleware>
                </Layout>
              }
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default connect((state) => ({ layout: state.Layout }), null)(App);







