// import React from "react";
// import ReactApexChart from "react-apexcharts";

// const SalesAnalyticsChart = ({ trafficData }) => {
//   // Ensure trafficData exists and is structured as expected
//   if (!trafficData || !trafficData.vehicleCounts) {
//     return <p>No traffic data available</p>;
//   }


// console.log("TrafficVehicle!!!!!!!!!!!!!! ",trafficData.vehicleCounts)
   
//   // Extract time slots (x-axis labels)
//   const timeSlots = Object.keys(trafficData.vehicleCounts);

//   const colorMapping = {
//     "text-danger": "#d9534f", // Bootstrap's danger color
//     "text-info": "#5bc0de", // Bootstrap's info color
//     "text-warning": "#f0ad4e", // Bootstrap's warning color

//     "text-success": "#5cb85c", // Bootstrap's success color
//   };

//   // Prepare the ApexCharts series dynamically based on trafficData
//   const chartSeries = [
//     {
//       name: "Cars",
//       type: "column",
//       data: timeSlots.map(
//         (timeSlot) => trafficData.vehicleCounts[timeSlot].car
//       ),
//     },
//     {
//       name: "Vans",
//       type: "column",
//       data: timeSlots.map(
//         (timeSlot) => trafficData.vehicleCounts[timeSlot].van
//       ),
//     },
//     {
//       name: "Buses",
//       type: "column",
//       data: timeSlots.map(
//         (timeSlot) => trafficData.vehicleCounts[timeSlot].bus
//       ),
//     },
//     {
//       name: "Motorcycles",
//       type: "column",
//       data: timeSlots.map(
//         (timeSlot) => trafficData.vehicleCounts[timeSlot].motorbike
//       ),
//     },
//     // {
//     //   name: "Total Traffic",
//     //   type: "area",
//     //   data: timeSlots.map((timeSlot) => trafficData.vehicleCounts[timeSlot].total),
//     // },
//     // {
//     //   name: "ISB to LHR",
//     //   type: "line",
//     //   data: timeSlots.map((timeSlot) => trafficData.vehicleCounts[timeSlot].isbToLhr),
//     // },
//     // {
//     //   name: "LHR to ISB",
//     //   type: "line",
//     //   data: timeSlots.map((timeSlot) => trafficData.vehicleCounts[timeSlot].lhrToIsb),
//     // },
//   ];

//   // ApexCharts options
//   const chartOptions = {
//     options: {
//       chart: {
//         height: 338,
//         type: "line",
//         stacked: false,
//         toolbar: {
//           show: false,
//         },
//       },
//       stroke: {
//         width: [1, 1, 1, 1, 1, 1],
//         curve: "smooth",
//       },
//       plotOptions: {
//         bar: {
//           columnWidth: "40%",
//         },
//       },
//       // colors: [

//       //   "#ff3d00", // Cars
//       //    "#ff9900", //Vans
//       //   "#00bcd4", // Buses
//       //   "#8bc34a", // Motorcycles
//       //   // "#0370ff", // Total Traffic
//       //   // "#f44336", // ISB to LHR
//       //   // "#4caf50", // LHR to ISB
//       // ],
//       colors: [
//         colorMapping["text-danger"], // Cars

//         colorMapping["text-info"], // Vans
//         colorMapping["text-warning"], // Buses
//         colorMapping["text-success"], // Motorcycles
//       ],
//       fill: {
//         opacity: [0.65, 1, 0.35, 1],
//         gradient: {
//           inverseColors: false,
//           shade: "light",
//           type: "vertical",
//           opacityFrom: 0.65,
//           opacityTo: 0.4,
//           stops: [0, 100, 100, 100],
//         },
//       },
//       labels: timeSlots, // Use the time slots as x-axis labels
//       markers: {
//         size: 0,
//       },
//       dataLabels: {
//         enabled: false,
//       },
//       xaxis: {
//         categories: timeSlots, // Set categories as time slots
//         type: "category", // Use category type for non-datetime x-axis
//       },
//       yaxis: {
//         title: {
//           text: "Number of vehicles",
//         },
//       },
//       tooltip: {
//         shared: true,
//         intersect: false,
//         y: {
//           formatter: "{value} vehicles",
//         },
//       },
//       grid: {
//         borderColor: "#f1f1f1",
//         padding: {
//           bottom: 15,
//         },
//       },
//     },
//   };

//   return (
//     <div>
//       <h1>Traffic Data Visualization</h1>
//       {timeSlots.length > 0 ? (
//         <ReactApexChart
//           options={chartOptions.options}
//           series={chartSeries}
//           type="line"
//           height={338}
//         />
//       ) : (
//         <p>Loading chart data...</p>
//       )}
//     </div>
//   );
// };

// export default SalesAnalyticsChart;





import React from "react";
import ReactApexChart from "react-apexcharts";

const SalesAnalyticsChart = ({ trafficData }) => {
  // Ensure trafficData exists and is structured as expected
  if (!trafficData || !trafficData.vehicleCounts) {
    return <p>No traffic data available</p>;
  }


  // Extract time slots (x-axis labels)
  const timeSlots = Object.keys(trafficData.vehicleCounts);

  const colorMapping = {
     "text-danger": "#d9534f", // Bootstrap's danger color
    "text-info": "#5bc0de", // Bootstrap's info color
    "text-warning": "#f0ad4e", // Bootstrap's warning color
    "text-success": "#5cb85c", // Bootstrap's success color
  };

  // Prepare the ApexCharts series dynamically based on trafficData
  const chartSeries = [];

  // Add Car series if data exists
  if (timeSlots.some((timeSlot) => trafficData.vehicleCounts[timeSlot].car > 0)) {
    chartSeries.push({
      name: "Cars",
      type: "column",
      data: timeSlots.map(
        (timeSlot) => trafficData.vehicleCounts[timeSlot].car
      ),
    });
  }

  // Add Van series if data exists
  if (timeSlots.some((timeSlot) => trafficData.vehicleCounts[timeSlot].van > 0)) {
    chartSeries.push({
      name: "Vans",
      type: "column",
      data: timeSlots.map(
        (timeSlot) => trafficData.vehicleCounts[timeSlot].van
      ),
    });
  }

  // Add Bus series if data exists
  if (timeSlots.some((timeSlot) => trafficData.vehicleCounts[timeSlot].bus > 0)) {
    chartSeries.push({
      name: "Buses",
      type: "column",
      data: timeSlots.map(
        (timeSlot) => trafficData.vehicleCounts[timeSlot].bus
      ),
    });
  }

  // Add Motorcycle series if data exists
  if (timeSlots.some((timeSlot) => trafficData.vehicleCounts[timeSlot].motorbike > 0)) {
    chartSeries.push({
      name: "Motorcycles",
      type: "column",
      data: timeSlots.map(
        (timeSlot) => trafficData.vehicleCounts[timeSlot].motorbike
      ),
    });
  }

  // ApexCharts options
  const chartOptions = {
    options: {
      chart: {
        height: 338,
        type: "line",
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [1, 1, 1, 1],
        curve: "smooth",
      },
      plotOptions: {
        bar: {
          columnWidth: "40%",
        },
      },
      colors: [
        colorMapping["text-danger"], // Cars
                colorMapping["text-info"], // Vans
                colorMapping["text-warning"], // Buses
                colorMapping["text-success"], // Motorcycles
      ],
      fill: {
        opacity: [0.65, 1, 0.35, 1],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.65,
          opacityTo: 0.4,
          stops: [0, 100, 100, 100],
        },
      },
      labels: timeSlots, // Use the time slots as x-axis labels
      markers: {
        size: 0,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: timeSlots, // Set categories as time slots
        type: "category",
       
      },
      yaxis: {
        title: {
          text: "Number of vehicles",
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: "{value} vehicles",
        },
      },
      grid: {
        borderColor: "#f1f1f1",
        padding: {
          bottom: 15,
          left: 100, 
          right: 90, 
        },
      },
    },
  };




  return (
    <div>
      <h1>Traffic Data Visualization</h1>
      {timeSlots.length > 0 ? (
        <ReactApexChart
          options={chartOptions.options}
          series={chartSeries}
          type="line"
          height={338}
        />
      ) : (
        <p>Loading chart data...</p>
      )}
    </div>
  );
};

export default SalesAnalyticsChart;
