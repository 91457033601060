



import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";

const New = ({ trafficData }) => {
  // console.log("TrafficData!!!!!!!!!!!!!!!!", trafficData);

  // Check if trafficData is available before processing
  if (!trafficData || !trafficData.vehicleCounts) {
    return (
      <Card>
        <CardBody>
          <CardTitle tag="h3" className="mb-4">
            Loading data...
          </CardTitle>
        </CardBody>
      </Card>
    );
  }

  // Prepare data for the table
  const tableData = Object.keys(trafficData.vehicleCounts).map((timeSlot) => {
    const count = trafficData.vehicleCounts[timeSlot];
    return {
      timestamp: timeSlot,
      total: count.total,
      lhrToIsb: count.lhrToIsb,
      isbToLhr: count.isbToLhr,
    };
  });

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3" className="mb-4">
          Traffic Data Overview
        </CardTitle>
        <div className="table-responsive">
          <table className="table table-striped table-custom table-bordered mb-0">
            <thead>
              <tr>
                <th>Timestamp</th>
                <th>Total</th>
                <th>LHR-ISB</th>
                <th>ISB-LHR</th>
              </tr>
            </thead>
            <tbody>
              {tableData.length > 0 ? (
                tableData.map(({ timestamp, total, lhrToIsb, isbToLhr }, index) => (
                  <tr key={index}>
                    <td>{timestamp}</td>
                    <td>{total || 0}</td>
                    <td>{lhrToIsb || 0}</td>
                    <td>{isbToLhr || 0}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
};

export default New;
