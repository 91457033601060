// import React from "react";
// import CountUp from "react-countup";
// import { Card, CardBody, Col, Row } from "reactstrap";

// const Widget = ({ trafficData }) => {
//   console.log("TrafficData in Widget:", trafficData);

//   // Ensure trafficData is not null or undefined before destructuring
//   const {
//     vehicleCounts = {},
//     totalIsbToLhrCounts = 0,
//     totalLhrToIsbCounts = 0,
//   } = trafficData || {};

//   // Calculate total vehicle count from vehicleCounts object
//   const calculateTotalVehicleCount = () => {
//     if (!vehicleCounts || typeof vehicleCounts !== "object") {
//       return 0; // Return 0 if vehicleCounts is not valid
//     }
//     return Object.values(vehicleCounts).reduce(
//       (sum, timeSlot) => sum + timeSlot.total,
//       0
//     );
//   };

//   // Calculate the highest vehicle type from the vehicleCounts
//   const getHighestVehicleType = () => {
//     if (!vehicleCounts || typeof vehicleCounts !== "object") {
//       return "None"; // Return "None" if vehicleCounts is not valid
//     }

//     let vehicleTypes = { car: 0, bus: 0, motorbike: 0, truck: 0, van: 0 };

//     // Sum counts for each vehicle type across all time slots
//     Object.values(vehicleCounts).forEach((timeSlot) => {
//       vehicleTypes.car += timeSlot.car || 0;
//       vehicleTypes.bus += timeSlot.bus || 0;
//       vehicleTypes.motorbike += timeSlot.motorbike || 0;
//       vehicleTypes.truck += timeSlot.truck || 0;
//       vehicleTypes.van += timeSlot.van || 0;
//     });

//     // Find the highest vehicle type
//     const highestType = Object.entries(vehicleTypes).reduce(
//       (max, [type, count]) => {
//         return count > max.count ? { type, count } : max;
//       },
//       { type: "", count: 0 }
//     );

//     return highestType.type
//       ? `${highestType.type} (${highestType.count})`
//       : "None";
//   };

//   // Get the total vehicle count from the trafficData
//   const totalVehicleCount = calculateTotalVehicleCount();
//   const highestVehicleType = getHighestVehicleType();

//   // Define the widget data with title, count, icon, and color
//   const widgetData = [
//     {
//       title: "Total Vehicle Count",
//       count: totalVehicleCount,
//       icon: "mdi mdi-cash-multiple text-primary",
//       color: "success",
//     },
//     {
//       title: "ISB to LHR Count",
//       count: totalIsbToLhrCounts,
//       icon: "mdi mdi-cart-check text-success",
//       color: "warning",
//     },
//     {
//       title: "LHR to ISB Count",
//       count: totalLhrToIsbCounts,
//       icon: "mdi mdi-account-group text-primary",
//       color: "primary",
//     },
//   ];

//   return (
//     <React.Fragment>
//       <Row>
//         {/* Existing Widgets (Total Vehicle, ISB to LHR, LHR to ISB) */}
//         {widgetData.map((widget, index) => (
//           <Col md={6} xl={3} key={index}>
//             <Card>
//               <CardBody>
//                 <div className="float-end">
//                   <div className="avatar-sm mx-auto mb-4">
//                     <span
//                       className={`avatar-title rounded-circle bg-light font-size-24 text-${widget.color}`}
//                     >
//                       <i className={widget.icon}></i>
//                     </span>
//                   </div>
//                 </div>
//                 <div>
//                   <p className="text-muted text-uppercase fw-semibold font-size-13">
//                     {widget.title}
//                   </p>
//                   <h4 className="mb-1 mt-1">
//                     <CountUp end={widget.count} />
//                   </h4>
//                   <p className="text-muted mt-3 mb-0">
//                     <span className="badge badge-soft-danger me-1">...</span>
//                     Last synced 40 minutes ago
//                   </p>
//                 </div>
//               </CardBody>
//             </Card>
//           </Col>
//         ))}

//         {/* New Widget for Highest Vehicle Type in a separate box */}
//         <Col md={6} xl={3}>
//           <Card>
//             <CardBody>
//               <div className="float-end">
//                 <div className="avatar-sm mx-auto mb-4">
//                   {/* Add any specific icon here if needed */}
//                 </div>
//               </div>
//               <div>
//                 <p className="text-muted text-uppercase fw-semibold font-size-13">
//                   Highest Vehicle Type
//                 </p>
//                 {/* <h4 className="mb-1 mt-1">{highestVehicleType || "No data"}</h4> */}
//                 <h4 className="mb-1 mt-1">
//   {highestVehicleType ? highestVehicleType.charAt(0).toUpperCase() + highestVehicleType.slice(1) : "No data"}
// </h4>

//                 <p className="text-muted mt-3 mb-0">
//                   <span className="badge badge-soft-danger me-1">...</span>
//                   Last synced 40 minutes ago
//                 </p>
//               </div>
//             </CardBody>
//           </Card>
//         </Col>
//       </Row>
//     </React.Fragment>
//   );
// };

// export default Widget;


import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import { Card, CardBody, Col, Row } from "reactstrap";


const Widget = ({ trafficData }) => {
  // console.log("TrafficData in Widget:", trafficData);

  const {
    vehicleCounts = {},
    totalIsbToLhrCounts = 0,
    totalLhrToIsbCounts = 0,
  } = trafficData || {};

  // State to store the previous total vehicle count
  const [prevTotalCount, setPrevTotalCount] = useState(0);
  const [currentTotalCount, setCurrentTotalCount] = useState(0);

  // State to store the previous ISB to LHR count
  const [prevTotalIsbToLhrCount, setPrevTotalIsbToLhrCount] = useState(0);
  const [currentTotalIsbToLhrCount, setCurrentTotalIsbToLhrCount] = useState(0);

  // State to store the previous LHR to ISB count
  const [prevTotalLhrToIsbCount, setPrevTotalLhrToIsbCount] = useState(0);
  const [currentTotalLhrToIsbCount, setCurrentTotalLhrToIsbCount] = useState(0);

  // Calculate total vehicle count from vehicleCounts object
  const calculateTotalVehicleCount = () => {
    if (!vehicleCounts || typeof vehicleCounts !== "object") {
      return 0;
    }
    return Object.values(vehicleCounts).reduce(
      (sum, timeSlot) => sum + timeSlot.total,
      0
    );
  };

  // Update total counts when trafficData changes
 useEffect(() => {
    const newTotalCount = calculateTotalVehicleCount();
    setPrevTotalCount(currentTotalCount); // Set the current count as previous
    setCurrentTotalCount(newTotalCount); // Update the current total count

    setPrevTotalIsbToLhrCount(currentTotalIsbToLhrCount);
    setCurrentTotalIsbToLhrCount(totalIsbToLhrCounts);

    setPrevTotalLhrToIsbCount(currentTotalLhrToIsbCount);
    setCurrentTotalLhrToIsbCount(totalLhrToIsbCounts);
  }, [trafficData]);

  // Calculate the highest vehicle type from the vehicleCounts
  const getHighestVehicleType = () => {
    if (!vehicleCounts || typeof vehicleCounts !== "object") {
      return "None"; // Return "None" if vehicleCounts is not valid
    }

    let vehicleTypes = { car: 0, bus: 0, motorbike: 0, truck: 0, van: 0 };

    // Sum counts for each vehicle type across all time slots
    Object.values(vehicleCounts).forEach((timeSlot) => {
      vehicleTypes.car += timeSlot.car || 0;
      vehicleTypes.bus += timeSlot.bus || 0;
      vehicleTypes.motorbike += timeSlot.motorbike || 0;
      vehicleTypes.truck += timeSlot.truck || 0;
      vehicleTypes.van += timeSlot.van || 0;
    });

    // Find the highest vehicle type
    const highestType = Object.entries(vehicleTypes).reduce(
      (max, [type, count]) => {
        return count > max.count ? { type, count } : max;
      },
      { type: "", count: 0 }
    );

    return highestType.type
      ? `${highestType.type} (${highestType.count})`
      : "None";
  };

  // Get the total vehicle count from the trafficData
  const highestVehicleType = getHighestVehicleType();

  const widgetData = [
    {
      title: "Total Vehicle Count",
      count: currentTotalCount,
      prevCount: prevTotalCount, // Pass previous count for CountUp
      icon: "mdi mdi-cash-multiple text-primary",
      color: "success",
    },
    {
      title: "ISB to LHR Count",
      count: currentTotalIsbToLhrCount,
      prevCount: prevTotalIsbToLhrCount, // Pass previous ISB to LHR count for CountUp
      icon: "mdi mdi-cart-check text-success",
      color: "warning",
    },
    {
      title: "LHR to ISB Count",
      count: currentTotalLhrToIsbCount,
      prevCount: prevTotalLhrToIsbCount, // Pass previous LHR to ISB count for CountUp
      icon: "mdi mdi-account-group text-primary",
      color: "primary",
    },
  ];

  return (
    <React.Fragment>
      <Row>
        {widgetData.map((widget, index) => (
          <Col md={6} xl={3} key={index}>
            <Card>
              <CardBody>
                <div className="float-end">
                  <div className="avatar-sm mx-auto mb-4">
                    <span
                      className={`avatar-title rounded-circle bg-light font-size-24 text-${widget.color}`}
                    >
                      <i className={widget.icon}></i>
                    </span>
                  </div>
                </div>
                <div>
                  <p className="text-muted text-uppercase fw-semibold font-size-13">
                    {widget.title}
                  </p>
                  <h4 className="mb-1 mt-1">
                    <CountUp
                      start={widget.prevCount || 0} // Start from the previous count
                      end={widget.count} // End at the current count
                      duration={1.5} // Animation duration in seconds
                    />
                  </h4>

                  <p className="text-muted mt-3 mb-0">
                    <span className="badge badge-soft-danger me-1">...</span>
                    Last synced 40 minutes ago
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}

        <Col md={6} xl={3}>
          <Card>
            <CardBody>
              <div className="float-end">
                <div className="avatar-sm mx-auto mb-4">
                  {/* Add any specific icon here if needed */}
                </div>
              </div>
              <div>
                <p className="text-muted text-uppercase fw-semibold font-size-13">
                  Highest Vehicle Type
                </p>
                <h4 className="mb-1 mt-1">
                  {highestVehicleType
                    ? highestVehicleType.charAt(0).toUpperCase() +
                      highestVehicleType.slice(1)
                    : "No data"}
                </h4>

                <p className="text-muted mt-3 mb-0">
                  <span className="badge badge-soft-danger me-1">...</span>
                  Last synced 40 minutes ago
                </p>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Widget;
